import React, { useState } from 'react';
import { pageTitle } from '../PageTitle';
import BreadCrumb  from '../BreadCrumb';
import HeaderOne from '../Header/HeaderOne';
import FooterOne from '../Footer/FooterOne';
import { Link } from 'react-router-dom';
import two from "../../assets/images/resource/man.png";
import service from "../../assets/images/resource/service-details.jpg";
import basebg from "../../assets/images/resource/base.png";


const Services = () => {
      pageTitle('Services Details');
      
      const [formData, setFormData] = useState({
        name: '',
        email: '',
        subject: '',
        phone: '',
        message: ''
      });
    
    
    
      const handleSubmit = (e) => {
        e.preventDefault();
    
        // Your logic to handle form submission goes here
        console.log('Form submitted:', formData);
    
        // Clear the form fields after submission if needed
        setFormData({
          name: '',
          email: '',
          subject: '',
          phone: '',
          message: ''
        });
      };

      const servicesData = [
        { id: 1, title: 'Automation Tools ', slug: '/automation-tool' },
        { id: 2, title: 'Custom Erp Solutions', slug: '/custom-erp-solutions' },
        { id: 3, title: 'Custom Software Development', slug: '/custom-software' },
        { id: 4, title: 'Mobile App Development', slug: '/mobile-app' },
        { id: 5, title: 'Digital Marketing', slug: '/digital-marketing' },
        { id: 6, title: 'Web Development & Design', slug: '/web-development' },
      ];
    
      const renderServices = () => {
        return servicesData.map((service) => (
          <li key={service.id} className={service.slug === '/automation-tool' ? 'active' : ''}>
            <Link to={service.slug}>{service.title}</Link>
          </li>
        ));
      };
     
  return (
    
    <>
        <HeaderOne></HeaderOne>
        <BreadCrumb></BreadCrumb>
        <div className="service__details p_relative ">
            <div className="auto-container">
                <div className="row">
                    <div className="col-xl-4 col-lg-6 col-md-12">
                        <div className="service__details__left">
                            <div className="sidebar__widget">
                                <div className="all__service">
                                    <h4>All Services</h4>
                                </div>
                                <ul className="service__details__1__cat">
                                    {renderServices()}
                                </ul>
                            </div>
                            <div className=" sidebar__widget_network" style={{ backgroundImage: `url(${basebg})` }}>                            
                                <h3>Contact Us For Any Require</h3>
                                <div className="services__icon">
                                    <i className="icon-19"></i>
                                </div>
                                <p>Need help?  Call us:</p>
                                <h4>+447366606750</h4>
                            </div> 
                        </div>
                    </div>
                    <div className="col-xl-8 col-lg-12 col-md-12">
                        <div className="service__right">
                            <figure>
                                <img src={service} alt="" />
                            </figure>
                            <h2>Our <span> Services</span> </h2>
                            <p>At Binary Tech Works, we offer a wide range of innovative digital solutions to help businesses succeed in an ever-evolving technological landscape. Whether you’re looking to automate processes, develop custom software, or enhance your online presence, our services are designed to deliver results. Here’s a summary of what we offer:</p>
                            <div className="row">
                                <div className="col-lg-6 col-md-12">
                                    <li> <Link to="/custom-software"><i className="icon-04"></i> Custom Software Development</Link></li>
                                </div>
                                <div className="col-lg-6 col-md-12">
                                    <li> <Link to="/automation-tool"><i className="icon-04"></i> Automation Tools</Link></li>
                                </div>
                                <div className="col-lg-6 col-md-12">
                                    <li> <Link to="/custom-erp-solutions"><i className="icon-04"></i> Custom ERP Solutions</Link></li>
                                </div>
                                <div className="col-lg-6 col-md-12">
                                    <li> <Link to="/digital-marketing"><i className="icon-04"></i> Digital Marketing</Link></li>
                                </div>
                                <div className="col-lg-6 col-md-12">
                                    <li> <Link to="/mobile-app"><i className="icon-04"></i> Mobile App Development</Link></li>
                                </div>
                                <div className="col-lg-6 col-md-12">
                                    <li> <Link to="/web-development"><i className="icon-04"></i> Web Development and Design</Link></li>
                                </div>
                            </div>
                            <p>
                            Each of these services is aimed at helping businesses achieve their digital transformation goals, backed by our team’s expertise in modern technologies and best practices. Let us help you take your business to the next level with our innovative solutions.
                            </p>
                            
                        </div>
                       
                    </div>
                </div>
                
            </div>
        </div>
        <div className="service__contact see__pad p_relative">
            <div className="auto-container">
                <div className="service__contact__block">
                    <div className="row">
                        <div className="col-lg-6 col-md-12">
                            <div className="service__form__data">
                                <div className="form__title">
                                    <div className="title__data">
                                        <div className="sub__title">
                                            <h4>Contact Us</h4>
                                        </div>
                                        <div className="title">
                                            <h2>Send message</h2>
                                        </div>
                                    </div>
                                </div>
                                <div className="form-inner">
                                    <form onSubmit={handleSubmit} method="post" action="contact.php" className="default-form"> 
                                        <div className="row clearfix">
                                            <div className="col-xl-6 form-group">
                                                <input type="name" name="name" placeholder="Your Name *" required="" />
                                            </div>
                                            <div className="col-xl-6 col-lg-12 form-group">
                                                <input type="email" name="email" placeholder="Your Email *" required="" />
                                            </div>
                                            <div className="col-xl-6 col-lg-12 form-group">
                                                <input type="text" name="subject" placeholder="Your Subject " required="" />
                                            </div>
                                            <div className="col-xl-6 col-lg-12 form-group">
                                                <input type="text" name="phone" placeholder="Your Phone " required="" />
                                            </div>
                                            <div className="ccol-xl-6 col-lg-12 form-group">
                                                <textarea name="message" placeholder="Message"></textarea>
                                            </div>
                                            <div className="col-xl-12 form-group message-btn btn-box">
                                                <button className="theme-btn theme-btn-one" type="submit" name="submit-form">Send your Message</button>
                                            </div>
                                        </div>
                                    </form>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-6 col-md-12 p_relative">
                            <div className="service__contact__img">
                                <figure>
                                    <img src={two} alt=" /" />
                                </figure>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <FooterOne></FooterOne>
    </>
  );
};

export default Services;
