import React, { useEffect } from 'react';
import Swiper from 'swiper';
import 'swiper/css';
import "swiper/css/pagination";
import authorImg1 from "../../../assets/images/resource/author-01.png";
import authorImg2 from "../../../assets/images/resource/author-02.png";
import authorImg3 from "../../../assets/images/resource/author-03.png";
import authorImg4 from "../../../assets/images/resource/author-04.png";

const TestimonialsSection = () => {
  const testimonials = [
    {
      authorImg: authorImg1,
      authorName: 'John Smith',
      authorRole: 'CEO, Smith & Co.',
      rating: [1, 2, 3, 4, 5],
      text: 'Binary Tech Works really came through for us. We needed a custom software solution that fit our specific needs, and they delivered exactly what we wanted. Their team understood our challenges and made the entire process easy to follow. The software is already making a huge difference in how we operate daily.',
    },
    {
      authorImg: authorImg2,
      authorName: 'Sarah Thompson',
      authorRole: 'Marketing Director, Thompson Foods',
      rating: [1, 2, 3, 4, 5],
      text: 'Working with Binary Tech Works was a game-changer for our digital marketing strategy. They helped us boost our online presence with SEO and social media, and we’ve seen a significant increase in traffic and customer engagement. Their team was responsive, professional, and explained everything clearly.',
    },
    {
      authorImg: authorImg3,
      authorName: 'David Lee',
      authorRole: 'Operations Manager, Lee Logistics',
      rating: [1, 2, 3, 4, 5],
      text: 'We had a lot of manual tasks that were slowing us down, but Binary Tech Works created automation tools that took care of everything for us. Now our departments work together much more smoothly, and we’ve saved so much time and effort.',
    },
    {
      authorImg: authorImg4,
      authorName: 'Emily Brown',
      authorRole: 'Founder, Brown’s Boutique',
      rating: [1, 2, 3, 4, 5],
      text: 'Our website was outdated and not getting much attention. Binary Tech Works designed a beautiful, user-friendly website that has really transformed our business. We’re now getting more visitors, and sales have improved thanks to their work.',
    },
    {
      authorImg: authorImg1,
      authorName: 'Michael Davis',
      authorRole: 'CTO, Davis Tech Solutions',
      rating: [1, 2, 3, 4, 5],
      text: 'We were struggling with outdated systems, but Binary Tech Works developed a custom ERP for us that has completely streamlined our operations. Everything runs smoother now, and we’ve cut down on manual work. Their team was great at understanding our needs and making the transition easy.',
    },
    {
        authorImg: authorImg2,
        authorName: 'Jessica Martinez',
        authorRole: 'Owner, Martinez Clothing',
        rating: [1, 2, 3, 4, 5],
        text: 'I wanted an app for my store but had no idea where to start. Binary Tech Works made the process so simple. They built a mobile app that’s super easy to use and has really helped me connect with more customers. I couldn’t be happier with the results!',
      },
      {
        authorImg: authorImg3,
        authorName: 'Kevin Johnson',
        authorRole: 'Manager',
        rating: [1, 2, 3, 4, 5],
        text: 'Binary Tech Works automated our most time-consuming processes, and it’s been a huge relief. Our workflow is more efficient, and we don’t have to worry about small tasks piling up anymore. It’s like they knew exactly what we needed to make our operations smoother.',
      },
      {
        authorImg: authorImg4,
        authorName: 'Rachel White',
        authorRole: 'Managing Director, White Events',
        rating: [1, 2, 3, 4, 5],
        text: 'Our website was old and slow, and we needed a complete overhaul. Binary Tech Works redesigned everything from scratch, and now it looks modern and works flawlessly. The entire experience was stress-free, and they were incredibly helpful throughout the project.',
      },
      
    
  ];

  useEffect(() => {
    const swiper = new Swiper('.swiper-container', {
      preloadImages: false,
			loop: true,
			grabCursor: true,
			centeredSlides: false,
			resistance: true,
			resistanceRatio: 0.6,
			slidesPerView: 4,
			speed: 400,
			spaceBetween: 45,
			parallax: false,
			effect: "slide",
			active: 'active',
			autoplay: {
				delay: 800000000000,
				disableOnInteraction: false
			},
			navigation: {
				nextEl: '.button_next',
				prevEl: '.button_prev',
			},
			pagination: {
				el: '.slider__pagination',
				clickable: true,
			},
			breakpoints: {
        1800: {
          slidesPerView: 4,
        },
        1600: {
          slidesPerView: 3,
        },
        1400: {
          slidesPerView: 3,
        },
        1200: {
          slidesPerView: 2,
        },
        800: {
          slidesPerView: 2,
        },
        767: {
          slidesPerView: 1,
        }, 
        320: {
          slidesPerView: 1,
        }, 
      }
    });

    return () => {
      swiper.destroy();
    };
  }, []);

  return (
    <section className="testimonial__section p_relative">
      <div className="auto-container">
        <div className="testimonial__top">
          <div className="title__data">
            <div className="sub__title">
              <h4>Testimonial </h4>
            </div>
            <div className="title">
              <h2>What Our Client Says</h2>
            </div>
          </div>
          <div className="swiper__button">
            <div className="button_next"><i className="icon-06"></i></div>
            <div className="button_prev"><i className="icon-05"></i></div>
          </div>
        </div>
      </div>
      <div className="swiper-container">
        <div className="swiper-wrapper">
          {testimonials.map((testimonial, index) => (
            <div key={index} className="swiper-slide testimonials__block__one">
                <div className="test__bg"></div>
                <div className="testimonials__info">
                    <div className="authore__img">
                        <figure className="image">
                        <img src={testimonial.authorImg} alt={testimonial.authorName} />
                        </figure>
                    </div>
                    <div className="authore__rating">
                        <ul>
                        {testimonial.rating.map((_, i) => (
                            <li key={i}><i className="icon-13"></i></li>
                        ))}
                        </ul>
                    </div>
                    <div className="authore__info">
                        <h5>{testimonial.authorName}</h5>
                        <p>{testimonial.authorRole}</p>
                    </div>
                    <div className="testimonials__text">
                        <p>{testimonial.text}</p>
                    </div>
                </div>
                <div className="quote">
                    <span className="icon-03"></span>
                </div>
            </div>
          ))}
        </div>
      </div>
    </section>
  );
};

export default TestimonialsSection;
