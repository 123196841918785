import React from 'react';
import { Link } from 'react-router-dom';


const Service = () => {
    const servicesData = [
      {
        id: 1,
        title: 'Custom Software Development',
        description: 'We create bespoke software solutions tailored to your business requirements, enhancing operational efficiency.',
        icon: 'icon-04',
        href: '/services-details',
        data_aos_delay:'00ms',
      },
      {
        id: 2,
        title: 'Web Development and Design',
        description: 'Our team designs impactful, user-friendly websites that engage audiences and drive conversions.',
        icon: 'icon-08',
        href: '/services-details',
        data_aos_delay:'200ms',
      },
      {
        id: 3,
        title: 'Mobile App Development',
        description: 'We develop high-performance mobile apps for iOS and Android, keeping your business connected with customers on the go.',
        icon: 'icon-12',
        href: '/services-details',
        data_aos_delay:'400ms',
      },
      {
        id: 4,
        title: 'Digital Marketing',
        description: 'Our digital marketing services boost online visibility through SEO, social media marketing, and more.',
        icon: 'icon-04',
        href: '/services-details',
        data_aos_delay:'400ms',
      },
      {
        id: 5,
        title: 'Custom Erp Soltions',
        description: 'We build Custom ERP Solutions that simplify your business operations. Our systems integrate all your key functions, improving efficiency and providing real-time insights.',
        icon: 'icon-08',
        href: '/services-details',
        data_aos_delay:'400ms',
      },
      {
        id: 6,
        title: 'Automation Tool',
        description: 'Our Automation Tool streamlines company processes by seamlessly connecting departments, enhancing collaboration, and improving overall efficiency. With automated workflows, you can eliminate manual tasks and focus on strategic growth.',
        icon: 'icon-12',
        href: '/services-details',
        data_aos_delay:'400ms',
      },
    ];
    return (
        <section className="service__section p_relative">
            <div className="auto-container">
                <div className="service__data">
                    <div className="title__data">
                        <div className="sub__title">
                            <h4>Better Future</h4>
                        </div>
                        <div className="title">
                            <h2>Explore Our Services</h2>
                        </div>
                    </div>
                    <div className="row">
                        {servicesData.map((item, id)=>(
                            <div className="col-lg-4 col-md-6 col-sm-12 wow fadeInUp animated" data-wow-delay={item.data_aos_delay} data-wow-duration="1500ms" key={id}>
                            <div className="service__block">
                                <div className="service__icon">
                                    <i className={item.icon}></i>
                                    <div className="service__icon__two"></div>
                                </div>
                                <div className="service__text">
                                    <h4><Link to={item.href}>{item.title}</Link></h4>
                                    <p>{item.description}</p>
                                </div>
                                <div className="service__button">
                                    <Link to={item.href}><i className="icon-05"></i></Link>
                                </div>
                            </div>
                        </div>
                        ))}
                    </div>
                </div>
            </div>
        </section>
    );
};

export default Service;